import type { DataItemModel, ItemModel } from './types.ts';
import type { DataObject } from './DataObject.ts';
import type { DataObjectField, Fields } from './DataObject.Fields.ts';

import { markRaw } from 'vue';
import { getDataObjectById } from './store.ts';
import { getJsonItem } from './extensions.JsonChange.ts';

/** Global JSON fields map */
const jsonFields: Map<string, Map<string, Map<string, string>>> = new Map();

export default class DataItem<T extends ItemModel = ItemModel> {
    private _initialized: boolean = false;
    /**
     * Do not modify this extarnaly. Used to store reactive refrences in a raw boundry,
     * Needed so that we don't get into infinite reactive effects
     */
    private __raw__?: { dataObject?: DataObject<T> };
    protected _item: Partial<T> = {};
    protected _defaultValues: Partial<T> = {};
    protected _state: DataItemState;
    protected _onValueChanged?: (pKey: string, pNewValue: any, pOldValue: any, pItem: T, pRow: DataItemModel<T>) => void;
    protected _onSelected?: DataItemOptions<T>['onSelected'];
    protected _uniqueKeyField?: keyof T & string;
    /** DataObject fields used for JSON fields implementation  */
    protected _fields?: DataObjectField<keyof T>[];
    protected _getFields?: () => Fields;  
    /** The index of this DataItem in Storage */
    index: number;
    /** Id of the DataObject this item belongs to */
    dataObjectId: string;
    /** App id of the DataObject this item belongs to */
    appId: string;
    /** Previous committed values */
    oldValues: Partial<T> = {};
    
    masterRowIndex: number|null = null; 

    /**
     * Raw object containing refrences to various modules related to this item.
     * Currently only dataObject is available
     */
    get $() {
        this._initRawRefrences();
        return this.__raw__!;
    }
    /** Current value of the item */
    get item() {
        return this._item as T;
    }
    /** Default values used in RecordSource */
    get defaultValues() { return this._defaultValues; }
    /** Indicates if the DataItem has any changes */
    get hasChanges() { return this._state.hasChanges; }
    set hasChanges(value: boolean) { this._state.hasChanges = value; }
    /** Error message of this item */
    get error() { return this._state.error; }
    /** Will be true when the item is loading. This applies only when the rows are dynamicly loaded */
    get isLoading() { return this._state.isLoading; }
    /** Will be true when the item is saving */
    get isSaving() { return this._state.isSaving; }
    /** Will be true when the item is being deleted */
    get isDeleting() { return this._state.isDeleting; }
    /** Indicates that the item is a new record with no changes */
    get isEmpty() { return this._state.isEmpty; }
    /** Indicates that the item is a new recrod */
    get isNewRecord() { return this._state.isNewRecord; }
    /** Indicates that the item is from BatchData extension */
    get isBatchRecord() { return this._state.isBatchRecord; }
    /** Promise that is resolved when this item has finished loading */
    get loadingPromise() { return this._state.loadingPromise; };
    /** Indicates that the item is selected. Used by SelectionControl */
    get isSelected() { return this._state.isSelected; }
    set isSelected(value) {
        this._state.isSelected = value;
        if (this._onSelected) {
            try {
                this._onSelected(this.index, value);
            } catch (ex) {
                console.error(ex);
            }
        }
    }
    /** Helper edit mode state */
    get isInEditMode() { return this._state.isInEditMode; }
    set isInEditMode(pValue) { this._state.isInEditMode = pValue; }

    disableSaving = false;
    /** State of this item. Should not be modified outside of DataObject modules  */
    get state() { return this._state; }
    get updateStates() { return this._updateStates; }
    /**
     * Unique key for this item. Will be PrimKey if not null, 
     * otherwise will fallback to the item index
     */
    get key() {
        return this._uniqueKeyField
            ? this.item[this._uniqueKeyField]
            : this.primKey ?? this.index;
    }
    /**
     * Unique field for the item (PrimKey, ID) 
     * provided by the DataObject
     */
    get uniqueKeyField() { return this._uniqueKeyField; }
    /** PrimKey of this item */
    get primKey() { return this._item['PrimKey']; }
    /** Map of json aliases from the DataObject this item belongs to */
    get jsonFields() {
        if (jsonFields.get(this.appId)?.has(this.dataObjectId)) {
            return jsonFields.get(this.appId)!.get(this.dataObjectId)!;
        } else {
            return undefined;
        }
    }
    /** Initialize setter of a key for this DataItem */
    get updateSetter() { return this._updateSetter; }
    /** Current changed values, will return null if there are no changes on the item */
    get changes() {
        if (!this.hasChanges) { return null; }
        const changes: Partial<T> = {};
        for (const key in this.oldValues) {
            if (!key.toString().startsWith("_")) changes[key] = this._item[key];
        }
        return changes;
    }
    /** Indicates that the item is current (index mathces with currentIndex) */
    get current() { return this._state.current; }
    set current(value) { this._state.current = value; }

    get fileDownloadPath(): string {
        return this.getFilePath('download');
    }

    get fileViewPath(): string {
        return this.getFilePath('view');
    }

    get filePdfDownloadPath(): string {
        return this.getFilePath('download-pdf');
    }

    get filePdfViewPath(): string {
        return this.getFilePath('view-pdf');
    }

    constructor(pIndex: number, pItem: T, pOptions: DataItemOptions<T>) {
        this.index = pIndex;
        this.dataObjectId = pOptions.dataObjectId;
        this.appId = pOptions.appId;
        this._onValueChanged = pOptions.onValueChanged;
        this._uniqueKeyField = pOptions.uniqueKeyField;

        if (pOptions.fields != null) {
            this._fields = pOptions.fields;
        }
        if (pOptions.getFields) {
            this._getFields = pOptions.getFields;
        }
        if (pOptions.onSelected) {
            this._onSelected = pOptions.onSelected;
        }

        this._state = new DataItemState({
            isNewRecord: false
        });

        this.extendItem(pItem, pOptions.skipSetters);
    }

    /**
     * Initialize modules that depend on this item such as JSON fields. 
     * This should be called right after creating a new DataItem. 
     * Can be called only once.
     */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        if (this._fields != null) {
            if (!jsonFields.has(this.appId)) {
                jsonFields.set(this.appId, new Map());
            }
            if (!jsonFields.get(this.appId)!.has(this.dataObjectId)) {
                jsonFields.get(this.appId)!.set(this.dataObjectId, new Map());
            }
            const dataObjectJsonFields = jsonFields.get(this.appId)!.get(this.dataObjectId)!;
            for (const field of this._fields) {
                if (!!field.jsonAlias) {
                    dataObjectJsonFields.set(field.name, field.jsonAlias!);
                }
            }
        }
        if (this.jsonFields != null && this.jsonFields.size > 0) {
            for (const key in this._item) {
                this._setJsonAliasField(key, this._item as T);
            }

            this.extendItem(this._item as T);
        }
    }

    /** Attempt to save this item */
    async save() {
        if (!this.hasChanges) { return []; }
        const ds = getDataObjectById(this.dataObjectId, this.appId);
        return ds.save(this.index);
    }

    /** Attempt to delete this item */
    async delete() {
        const ds = getDataObjectById(this.dataObjectId, this.appId);
        return ds.delete(this.index);
    }

    /**
     * Cancel all changes and reset the item state. 
     * If a key is provieed then will only cancel changes for that field.
     */
    cancelChanges(pKey?: keyof T & string) {
        if (pKey) {
            if (this.oldValues.hasOwnProperty(pKey)) {
                (this as any)[pKey] = this.oldValues[pKey];
            }
            if (Object.keys(this.oldValues).length === 0) {
                this.reset();
            }
        } else {
            for (const key in this.oldValues) {
                (this as any)[key] = this.oldValues[key];
            }
            this.reset();
        }
    }

    /**
     * Clear current items and reset the state.
     * Any uncanceled changes will be treated as the new current values
     */
    reset() {
        this.oldValues = {};
        this._state.reset();
    }

    /**
     * Add new fields to the item for tracking changes. Will also resovle loading if 
     * the provided item has at least one property and the item is still in loading state.
     */
    extendItem(pItem: T, pSkipSetters = false) {
        if (this.isLoading && Object.keys(pItem).length > 0) {
            this._state.resolveLoad();
        }

        this._item = pItem;
        if (!pSkipSetters) {
            this._updateSetters();
        }
    }

    /**
     * Update the error for the row. Can be either an Error 
     * object or a message string.
     */
    updateError(ex: Error | string | { error: string }) {
        this._state.isSaving = false;
        this._state.isDeleting = false;
        if (typeof ex === 'string') {
            this._state.error = ex;
        } else if (ex instanceof Error) {
            this._state.error = ex.message;
            if ((ex as any).errorType != null) {
                this._state.errorType = (ex as any).errorType;
            }
        } else if (ex?.error) {
            this._state.error = ex.error
        }
    }

    /** Remove the error for the row */
    removeError() {
        if (this._state.error) {
            this._state.error = null;
            this._state.errorType = null;
        }
    }

    updateOrExtendItem(pValues: Partial<T>) {
        for (const key in pValues) {
            if (!this.hasOwnProperty(key)) {
                this.updateSetter(key);
            }
            this._item[key] = pValues[key];
        }
    }

    public getFilePath(mode: 'view' | 'download' | 'view-pdf' | 'download-pdf', options?: {
        viewName?: string,
        primKey?: string,
        primKeyColumnName?: string,
        fileName?: string,
        fileNameColumnName?: string,
        queryString?: string,
    }): string {
        let { viewName, primKey, primKeyColumnName, fileName, fileNameColumnName, queryString } = options ?? {};

        viewName ??= getDataObjectById(this.dataObjectId, this.appId).viewName;
        primKey ??= primKeyColumnName ? this.item[primKeyColumnName] : this.item.PrimKey;
        fileName ??= fileNameColumnName ? this.item[fileNameColumnName] : this.item.FileName;

        let basePath = (() => {
            switch (mode) {
                case 'download':
                    return '/nt/api/file/download';
                case 'download-pdf':
                    return '/nt/api/download-pdf';
                case 'view':
                    return '/nt/api/file/view';
                case 'view-pdf':
                    return '/nt/api/view-pdf';
            }
        })();

        let url = `${basePath}/${viewName}/${primKey}`;

        if (fileName !== undefined && fileName.length > 0) {
            url += `?file-name=${encodeURIComponent(fileName)}`;
        }

        if (queryString !== undefined && queryString.length > 0) {
            url += `${url.includes('?') ? '&' : '?'}${queryString}`;
        }

        return url;
    }

    /** Add setters and getters for each property on the inner item */
    protected _updateSetters() {
        this._bulkUpdateSetters(Object.keys(this._item));
    }

    /** Add setter and getter for a property of the inner item */
    protected _updateSetter(pKey: keyof T & string) {
        if (this.hasOwnProperty(pKey)) { return; }
        if (this._item[pKey] !== null && !this._defaultValues.hasOwnProperty(pKey)) {
            this._defaultValues[pKey] = this._item[pKey];
        }

        const isDate = this._checkIfDate(pKey);
        const isDateTimeOffset = this._checkIfDateTimeOffset(pKey);

        Object.defineProperty(this, pKey, {
            get() { return this._item[pKey]; },
            set(value) {
                if (isDate && value) {
                    value = this._dropTimezoneInfo(value);
                } else if (isDateTimeOffset && value instanceof Date) {
                    const offset = this._getTimezoneOffset(value);
                    value = `${this._dropTimezoneInfo(value)}${offset}`;
                } else if (value instanceof Date) {
                    value = value.toISOString();
                }
                this._markChange(pKey, value);
            }
        });
    }

    _bulkUpdateSetters(pKeys: (keyof T & string)[]) {
        const properties: Record<string, any> = {};
        for (const key of pKeys) {

            if (this.hasOwnProperty(key)) { continue; }
            if (this._item[key] !== null && !this._defaultValues.hasOwnProperty(key)) {
                this._defaultValues[key] = this._item[key];
            }

            const isDate = this._checkIfDate(key);
            const isDateTimeOffset = this._checkIfDateTimeOffset(key);

            properties[key] = {
                get() { return this._item[key] },
                set(value: any) {
                    if (isDate && value) {
                        value = this._dropTimezoneInfo(value);
                    } else if (isDateTimeOffset && value instanceof Date) {
                        const offset = this._getTimezoneOffset(value);
                        value = `${this._dropTimezoneInfo(value)}${offset}`;
                    } else if (value instanceof Date) {
                        value = value.toISOString();
                    }
                    this._markChange(key, value);
                }
            }
        }

        Object.defineProperties(this, properties);
    }

    protected _dropTimezoneInfo(pDate: Date) {
        if (pDate instanceof Date) {
            const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');
            let dateString = `${pDate.getFullYear()}-`
                + `${formatDoubleDigits(pDate.getMonth() + 1)}-`
                + `${formatDoubleDigits(pDate.getDate())}T`
                + `${formatDoubleDigits(pDate.getHours())}:`
                + `${formatDoubleDigits(pDate.getMinutes())}:`
                + `${formatDoubleDigits(pDate.getSeconds())}`;
            const ms = pDate.getMilliseconds();
            dateString += `.${String(ms).padStart(3, '0')}`
            return dateString;
        }
        return pDate;
    }

    protected _getTimezoneOffset(pValue: Date) {
        const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');
        const offset = pValue.getTimezoneOffset() * -1;
        const sign = offset >= 0 ? '+' : '-';
        const hours = formatDoubleDigits(Math.floor(offset / 60));
        const minutes = formatDoubleDigits(Math.floor(offset % 60));
        return `${sign}${hours}:${minutes}`;
    }

    protected _checkIfDate(pKey: string) {
        let field: DataObjectField | undefined;
        if (this._getFields) {
            field = this._getFields()[pKey as keyof Fields];
        } else {
            field = this._fields?.find(x => x.name == pKey);
        }
        return field && (field.dataType == 'date' || field.dataType == 'datetime' || field.type == 'date'); 
    }
    protected _checkIfDateTimeOffset(pKey: string) {
        let field: DataObjectField | undefined;
        if (this._getFields) {
            field = this._getFields()[pKey as keyof Fields];
        } else {
            field = this._fields?.find(x => x.name == pKey);
        }
        return field?.dataType === 'datetimeoffset';
    }

    /** Track change of a property on the inner item */
    protected _markChange(pKey: keyof T & string, pValue: any) {
        let triggerValueChange = this.item[pKey] !== pValue;
        if (!this.oldValues.hasOwnProperty(pKey)) {
            this.oldValues[pKey] = this._item[pKey];
        }
        this._item[pKey] = pValue;
        if (pKey.startsWith('_') || pKey.startsWith('o_')) { return; }

        if (this.jsonFields?.has(pKey)) {
            this._setJsonAliasField(pKey, this._item as T);
        }

        this._state.error = null;
        this._state.errorType = null;
        this._updateStates();
        if (this._onValueChanged && triggerValueChange) {
            this._onValueChanged(pKey, pValue, this.oldValues[pKey], this._item as T, ((this as any) as DataItemModel<T>));
        }
    }

    /** Clear any old values that match with current values and update the item state */
    protected _updateStates() {
        for (const key in this.oldValues) {
            if (this.oldValues[key] === null && this._item[key] === '') {
                delete this.oldValues[key];
            } else if (this.oldValues[key] === this._item[key]) {
                delete this.oldValues[key];
            }
        }

        this._state.hasChanges = Object.keys(this.oldValues).length > 0;
        this._state.isEmpty = !this.hasChanges && this.isNewRecord;
    }

    protected _setJsonAliasField(pKey: keyof T & string, pItem: T) {
        if (this.jsonFields?.has(pKey)) {
            const jsonAlias = this.jsonFields.get(pKey)!;
            (pItem as any)[jsonAlias] = getJsonItem<T>(pItem[pKey], jsonAlias, pKey, this as any);
        }
    }

    /**
     * Create the raw object and assing proxy refrences to it. 
     * Needed to avoid infinite dependency tracking issues
     */
    protected _initRawRefrences() {
        if (this.__raw__ == null) {
            this.__raw__ = markRaw({});
        }
        if (this.__raw__!.dataObject == null) {
            this.__raw__!.dataObject = getDataObjectById(this.dataObjectId, this.appId);
        }
    }
}

/** Helper class for storing DataItem state */
export class DataItemState {
    private _loadingPromise: Promise<boolean>;
    private _loadingResolve!: () => void;

    get loadingPromise() { return this._loadingPromise; }
    get resolveLoad() { return this._loadingResolve; }

    current = false;

    hasChanges = false;
    error: string | null = null;
    errorType: number | null = null;

    isLoading = true;
    isSaving = false;
    isDeleting = false;

    isNewRecord: boolean;
    isBatchRecord: boolean = false;
    isEmpty = false;
    isSelected = false;
    isInEditMode = false;

    constructor(pOptions: {
        isNewRecord: boolean
    }) {
        this.isNewRecord = pOptions.isNewRecord;
        this._loadingPromise = new Promise(res => this._loadingResolve = () => {
            this.isLoading = false;
            res(true);
        });
    }

    /** Set the state to the initial value */
    reset() {
        this.hasChanges = false;
        this.error = null;
        this.errorType = null;
        this.isSaving = false;
        this.isDeleting = false;
        this.isInEditMode = false;
    }
}

export type DataItemOptions<T extends ItemModel = ItemModel> = {
    dataObjectId: string,
    appId: string,
    fields?: DataObjectField<keyof T>[],
    uniqueKeyField?: string,
    onValueChanged?: (pKey: string, pNewValue: any, pOldValue: any, pItem: T, pRow: DataItemModel<T>) => void;
    onSelected?: (pIndex: number, pValue: boolean) => void;
    getFields?: () => Fields;
    skipSetters?: boolean;
};

/** Helper function for cleaning up JSON fields */
export function removeDataObjectJSONFields(pDataObjectId: string, pAppId: string) {
    if (jsonFields.get(pAppId)?.has(pDataObjectId)) {
        jsonFields.get(pAppId)!.delete(pDataObjectId);
    }
}

type PublicConstructor<T, P extends unknown[]> = new (...args: P) => T;

type DataItemClass<T extends ItemModel> = Omit<
    DataItem<T>,
    never
>;

export type DataItemConstructor<T extends ItemModel> = PublicConstructor<DataItemClass<T>, ConstructorParameters<typeof DataItem<T>>>;

/** Generate a DataItem class for the given model */
export function getDataItemModel<T extends ItemModel>(pOptions: {
    prefix: string,
    keys?: string[],
    fields: DataObjectField[],
}): PublicConstructor<DataItemClass<T>, ConstructorParameters<typeof DataItem<T>>> {

    const DataItemModelClass = class extends DataItem<T> {
        static registeredKeys = new Set<string>();

        /** Update getters and setters for change tracking */
        static updateSetters(pKeys: string[]) {
            for (const key of pKeys) {
                if (DataItemModelClass.registeredKeys.has(key)) { continue; }
                DataItemModelClass.registeredKeys.add(key);

                const field = pOptions.fields.find(x => x.name == key);
                const isDate = field && (field.dataType == 'date' || field.dataType == 'datetime' || field.type == 'date'); ;
                const isDateTimeOffset = field?.dataType === 'datetimeoffset';;
                Object.defineProperty(this.prototype, key, {
                    get() { return this._item[key]; },
                    set(value) {
                        if (isDate && value) {
                            value = this._dropTimezoneInfo(value);
                        } else if (isDateTimeOffset && value instanceof Date) {
                            const offset = this._getTimezoneOffset(value);
                            value = `${this._dropTimezoneInfo(value)}${offset}`;
                        } else if (value instanceof Date) {
                            value = value.toISOString();
                        }
                        this._markChange(key, value);
                    }
                });
            }
        }

        constructor(...args: ConstructorParameters<typeof DataItem<T>>) {
            super(...args);
        }
    }

    if (pOptions.keys) {
        DataItemModelClass.updateSetters(pOptions.keys);
    }

    Object.defineProperty (DataItemModelClass, 'name', {value: `${pOptions.prefix}_DataItemModel`});

    return DataItemModelClass;
}